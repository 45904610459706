import { isString, isPlainObject } from 'lodash';
/**
 * Conversions
 */
export const mbToBytes = mbs => mbs * 1048576;

export const dataURItoBlob = (dataURI, filename) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ia], { type: mimeString });
  if (filename) {
    blob.name = filename;
  }
  return blob;
};

/**
 * Date helpers
 */

export const isOutOfYearRange = (date, min, max) => {
  const dateYear = new Date(date).getFullYear();
  if (dateYear < min || dateYear > max) {
    return true;
  }
  return false;
};

export const secondsToTime = secs => {
  const hours = Math.floor(secs / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  return {
    hours,
    minutes,
    seconds,
  };
};

export const daysInMonth = (month, year) => new Date(year, month, 0).getDate();

/**
 * Validations
 */

export const isValidEmail = email => {
  // eslint-disable-next-line no-useless-escape
  const filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return filter.test(email);
};

export const isValidPhone = phone => phone.replace(/\D/g, '').length >= 11;

export const isValidPhoneLengthWithoutUSCode = phone => phone.length === 10;

export const isInvalidName = name => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^.*?(?=[\^0-9`!@#\$%\^&*+_=]).*$/g;

  return regex.test(name);
};

/**
 * Object and arrays
 */
export const insert = (arr, index, ...items) => {
  const newArr = [...arr];
  newArr.splice(index, 0, ...items);
  return newArr;
};

export const removeByKey = (myObj, deleteKey) =>
  Object.keys(myObj)
    .filter(key => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = myObj[current];
      return result;
    }, {});

export const trimObjectValues = (obj = {}) => {
  const result = Object.keys(obj).reduce((acc, key) => {
    switch (true) {
      case isString(obj[key]):
        acc[key] = obj[key].trim();

        break;

      case isPlainObject(obj[key]):
        acc[key] = trimObjectValues(obj[key]);

        break;

      default:
        acc[key] = obj[key];

        break;
    }

    return acc;
  }, {});

  return result;
};
