/* eslint-disable no-param-reassign */
import Awesomplete from 'awesomplete';
import { isElement } from 'lodash';

import { parseDollars } from '../util/Formatter';
import { isValidEmail, isValidPhone } from '../util/helpers';
import { STATE_HASH, STATES } from '../constants';

export const fillInAddress = (addressFields, autocomplete) => {
  // Get the place details from the autocomplete object.
  const place = autocomplete.getPlace();
  const { street_1, city, state, zip, country } = addressFields;

  let i;

  // clear fields for every change.
  street_1.value = '';
  city.value = '';
  state.value = '';
  zip.value = '';

  // only _lender_application.html.erb has the country
  // input field, so check for that.
  if (country !== undefined) {
    country.value = '';
  }

  // Get each component of the address from the place details
  // and fill the corresponding field on the form.

  if (place.address_components !== undefined) {
    for (i = 0; i < place.address_components.length; i += 1) {
      const addressType = place.address_components[i].types[0];
      if (
        addressType === 'locality' ||
        place.address_components[i].types[1] === 'sublocality'
      ) {
        city.value = place.address_components[i].long_name;
      }

      if (addressType === 'administrative_area_level_1') {
        state.value = place.address_components[i].short_name;
      }

      if (addressType === 'postal_code') {
        zip.value = place.address_components[i].long_name;
      }

      if (addressType === 'country' && country !== undefined) {
        country.value = place.address_components[i].short_name;
      }
    }

    street_1.value = `${place.address_components[0].long_name} ${place.address_components[1].long_name}`;
  }
};

export const pacSelectFirst = (input, addressFields) => {
  // store the original event binding function
  const newAddEventListener = input.addEventListener
    ? input.addEventListener
    : input.attachEvent;

  function addEventListenerWrapper(type, listener) {
    // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
    // and then trigger the original listener.
    if (type === 'keydown') {
      const origListener = listener;
      listener = event => {
        const suggestionSelected = $('.pac-item-selected').length > 0;
        if (event.which === 13 && !suggestionSelected) {
          const simulatedDownarrow = $.Event('keydown', {
            keyCode: 40,
            which: 40,
          });
          origListener.apply(input, [simulatedDownarrow]);
        }
        origListener.apply(input, [event]);
      };
    }
    newAddEventListener.apply(input, [type, listener]);
  }

  input.addEventListener = addEventListenerWrapper;
  input.attachEvent = addEventListenerWrapper;

  const autocomplete = new google.maps.places.Autocomplete(input, {
    types: ['geocode'],
    componentRestrictions: { country: 'us' },
  });

  autocomplete.setFields(['address_component']);

  google.maps.event.addListener(autocomplete, 'place_changed', () => {
    fillInAddress(addressFields, autocomplete);
  });
};

export const loadAutoComplete = $form => {
  const street_1 = $form.find('.full-address')[0];
  const city = $form.find('.locality')[0];
  const state = $form.find('.administrative_area_level_1')[0];
  const zip = $form.find('.postal_code')[0];
  const country = $form.find('.country')[0];
  const pacInput = $form.find('.autocomplete')[0];

  const addressFields = {
    street_1,
    city,
    state,
    zip,
    country,
  };

  pacSelectFirst(pacInput, addressFields);
};

export const stateTagsInit = field => {
  const list = Object.keys(STATE_HASH).map(state => ({
    label: `${state} - ${STATE_HASH[state]}`,
    value: state,
  }));

  const a = new Awesomplete(isElement(field) ? field : `#${field}`, {
    minChars: 1,
    autoFirst: true,
    filter: function(text, input) {
      return Awesomplete.FILTER_CONTAINS(text, input.match(/[^,]*$/)[0]);
    },

    item: function(text, input) {
      return Awesomplete.ITEM(text, input.match(/[^,]*$/)[0]);
    },

    replace: function(text) {
      const before = this.input.value.match(/^.+,\s*|/)[0];
      this.input.value = `${before}${text.value}, `;
    },
  });

  a.list = list;

  return a;
};

const trimAndFilter = $el =>
  $el
    .val()
    .split(',')
    .map(s => s.trim().toUpperCase())
    .filter(s => s);

export const formatStateValue = $el => {
  $el.val(trimAndFilter($el).join(', '));
};

export const validateStateList = $el => {
  const nonStates = trimAndFilter($el).filter(s => {
    return !STATES.includes(s);
  });

  const containsBadStates = nonStates.length > 0;
  return !containsBadStates;
};

const numberWithCommas = (num, stripDecimals) => {
  const parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return stripDecimals ? parts[0] : parts.join('.');
};

const handleCommas = (value, removeDecimals) => {
  value = value.replace(/[^\d.]+/g, '');
  return numberWithCommas(value, removeDecimals);
};

export const formatNumbersAfterTyped = stripDecimals => {
  $('input.number').on('blur', e => {
    // skip backspace
    if (e.originalEvent.data === null) return;

    const removeDecimals = e.target.className.includes('remove-decimals');
    const allowDecimalValue = e.target.value.replace(/[^\d.]+/g, '');
    const wholeNumValue = e.target.value.replace(/[^\d]+/g, '');
    const value = removeDecimals ? wholeNumValue : allowDecimalValue;
    e.target.value = numberWithCommas(value, stripDecimals || removeDecimals);
  });
};

export const formatNumbers = () => {
  // format numbers in input fields that are prepopulated
  $('.formatted-number').each(function formatNumber() {
    const removeDecimals = this.className.includes('remove-decimals');
    $(this).val(handleCommas(this.value, removeDecimals));
  });
};

export const sum = (array, attr) => {
  let output = 0;
  $(array).each(function iterate() {
    output += parseInt($(this).attr(attr), 10) || 0;
  });
  return output;
};

export const detectAddressAndSubmitForm = $form => {
  $form.find('.autocomplete').keydown(e => {
    if (e.which !== 13) {
      return;
    }
    e.preventDefault();

    // since #deal_street is a hidden input field, it doesn't read .change()
    // so it needs to be manually triggered.
    const $dealStreet = $form.find('#deal_street');
    $dealStreet.trigger('change');

    // the #deal_street hidden input field doesn't change until a result
    // is selected with "enter" or "tab"
    $dealStreet.change(() => {
      $(e.target)
        .closest('form')
        .submit();
    });
  });
};

export const normalizeStaticPhone = value => {
  if (!value) return '+1';

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 4)
    return `+${onlyNums.slice(0, 1)}(${onlyNums.slice(1, 4)})`;

  if (onlyNums.length <= 7) {
    return `+${onlyNums.slice(0, 1)}(${onlyNums.slice(1, 4)}) ${onlyNums.slice(
      4,
    )}`;
  }

  return `+${onlyNums.slice(0, 1)}(${onlyNums.slice(1, 4)}) ${onlyNums.slice(
    4,
    7,
  )}-${onlyNums.slice(7, 11)}`;
};

/**
 * Validations formatted for bootstrap form validator
 */

export const validators = {
  validations: {
    phone: $el => isValidPhone($el.val()),
    email: $el => isValidEmail($el.val()),
    maxnum: $el => {
      const val = parseDollars($el.val());
      return val <= parseInt($el.data('maxnum'), 10);
    },
    minnum: $el => {
      const val = parseDollars($el.val());
      return val >= parseInt($el.data('minnum'), 10);
    },
    dec: $el => {
      const val = $el.val();
      return !val.split('.')[1] && val.indexOf('.') == -1;
    },
    alpha: $el => {
      const val = $el.val();
      return !val.match(/[a-z]/i);
    },
    states: validateStateList,
  },
  errors: {
    email: 'Please enter a valid email address',
    phone: 'Please enter a valid phone number',
    maxnum: 'Must be less than $100,000,000.',
    minnum: 'Must be at least $0.',
    dec: 'Must be a whole number.',
    alpha: 'Must be a number.',
    states: 'Please enter valid state abbreviations',
  },
};

export function isFormValid($form) {
  let validFieldsCount = 0;

  const $fields = $form.find(':input:visible');

  $fields.each((i, elem) => {
    if (
      $(elem)
        .closest('.form-group')
        .hasClass('has-error')
    ) {
      return;
    }

    if (elem.checkValidity() === true) {
      validFieldsCount += 1;
    }
  });

  return validFieldsCount === $fields.length;
}
